var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "easemob" }, [
    _c("div", { staticClass: "contact", on: { click: _vm.connect } }, [
      _c("img", { attrs: { src: require("./img/chat.png") } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }