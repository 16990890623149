/**
 * @Author: panezhang
 * @Date: 2018-06-01 21:16:47.820
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 16:34:49
 */

/* global easemobim */

const EASEMOB_URL = '//kefu.easemob.com/webim/easemob.js';

export const connect = easemob => {
    window.easemobim = window.easemobim || {};
    const {configId = null} = easemob;
    const scriptUrl = configId ? `${EASEMOB_URL}?configId=${configId}` : EASEMOB_URL;

    easemobim.config = {
        hide: true,
        autoConnect: true,
        resources: true,
        configId
    };

    import('scriptjs')
        .then(script => {
            script = script.default || script; // eslint-disable-line no-param-reassign
            script(scriptUrl, () => (setTimeout(() => (easemobim.bind(easemob)), 500)));
        })
        .catch(console.error); // eslint-disable-line no-console
};

export default {
    name: 'Easemob',

    props: {
        easemob: {
            type: Object,
            required: true
        }
    },

    methods: {
        connect: () => connect(this.easemob)
    }
};
